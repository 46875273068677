@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.footer {
    width: 100%;
    margin-top: auto;
    position: relative;
    overflow: hidden;
    background: $white;
    border-bottom: 5px solid $secondColor;
    &_top {
        padding-top: 30px;
        padding-bottom: 20px;
        display: flex;
    }
    &_bottom {
        padding: 20px 0;
        display: flex;
        align-items: center; 
    }
    .touch_layer {
        @extend %iconElement;
        display: none;
    }
    &_menu {
        @extend %standardList;
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 280px;
        ul {
            @extend %standardList;
            li {
                padding-top: 10px;
                padding-right: 16px;
            }
            a {
                font-size: 130%;
                line-height: 18px;
            }
        }
        span {
            display: block;
        }
        > li {
            max-width: 33.33%;
            margin-bottom: 20px;
            > a,
            > div:first-child,
            span {
                font-family: $bold;
                font-size: 140%;
                line-height: 19px;
                &:after {
                    @extend %iconElement;
                }
            }
        }
        a {
            display: inline-block;
            vertical-align: top;
            color: inherit;
            @include linkHover();
            &[href*="news"] {
                @include secondColorHover;
            }
        }
    }
    .socials_list {
        margin: 0;
        padding: 35px 0 0 5px;
        list-style-type: none;
        display: flex;
        li:not(:first-child) {
            margin-left: 30px;
        }
        a {
            display: block;
            color: inherit; 
            font-size: 200%;
            @include linkHover();
            &:before {
                display: block;
            }
        }
    }
    .finance_partners {
        display: flex;
        a {
            display: block;
            margin-right: 30px;
            @include opacityHover();
        }
        img {
            display: block;
            width: auto;
            height: 24px;
        }
    }
    .copyrights {
        font-size: 110%;
        color: $black;
        line-height: 15px;
        padding-right: 30px;
        span {
            display: inline-block;
            vertical-align: top;
        }
    }
    .last_update {
        margin-left: auto;
        font-size: 110%;
        line-height: 15px;
        text-align: right;
        flex: 1;
        width: 20%;
        > span {
            display: inline-block;
            vertical-align: top;
            margin-left: 5px;
        }
        a {
            color: $linkColor;
            @include linkHover();
        }
    }
    @media #{$until959} {
        &_bottom {
            flex-wrap: wrap;
            align-items: flex-end;
        }
        &_top {
            flex-wrap: wrap;
        }
        .socials_list {
            flex: 0 0 100%;
            margin-bottom: 5px;
        }
        .finance_partners {

            @include overHidden();
        }
        //.last_update,
        //.copyrights {
        //    flex: 0 0 50%;
        //    max-width: 50%;
        //}
    }
    @media #{$until767} {
        .copyrights,
        .last_update {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: left;
        }
        .finance_partners {
            display: flex;
            flex-wrap: wrap;
            a {
                margin-bottom: 20px;
                margin-right: 20px
            }
        }
    }
    @media #{$until575} {
        &_menu {
            display: block;
            max-height: none;
            > li {
                margin-bottom: 0;
                max-width: 100%;
                &:not(:first-child) {
                    margin-top: 20px;
                }
                > div:first-child,
                .submenu_btn {
                    display: inline-flex;
                    align-items: center;
                }
                > .submenu_list {
                    @include overHidden();
                    @include animStyle(max-height 0.3s);
                    flex: 0 0 100%;
                    max-width: 100%;
                    max-height: 0;
                    li {
                        padding-top: 15px;
                    }
                }
                &.has_submenu {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    @include overHidden;
                }
                > .touch_layer {
                    display: block;
                    margin-left: 14px;
                    position: relative;
                    &:before {
                        @include psevdoAbs();
                        top: 0;
                        height: 100%;
                        right: 0;
                        width: 100vw;
                    }
                    &:after {
                        display: block;
                        content: "\e900";
                        color: $siteColor;
                        font-size: 0.7rem;
                        position: relative;
                        @include torigin(center);
                        @include animStyle(transform 0.3s);
                    }
                }
                &.opened {
                    .touch_layer:after {
                        @include transStyle(scaleY(-1));
                    }
                    > .submenu_list {
                        max-height: 400px;
                    }
                }
            }
        }

    }
}



@import '../../default-scss/extendsEnd';
