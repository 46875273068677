@mixin standardList() {
    padding: 0;
	margin: 0;
	list-style-type: none;
}
@mixin standardRowList() {
    padding: 0;
	margin: 0 $rowMargin;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
}
@mixin slider() {
	position: relative;
	white-space: nowrap;
	font-size: 0;
	overflow: hidden;
}
@mixin slide($width, $position) {
	display: inline-block;
	white-space: normal;
	font-size: 1rem;
	width: $width;
	vertical-align: $position;
}
@mixin centerCover() {
	background-size: cover;
	background-position: center;
}
@mixin animStyle($parameters) {
  	-o-transition: $parameters;
	-ms-transition: $parameters;
	-moz-transition: $parameters;
	-webkit-transition: $parameters;
	transition: $parameters;
}
@mixin animStyles($param1,$param2) {
	-o-transition: $param1,$param2;
  -ms-transition: $param1,$param2;
  -moz-transition: $param1,$param2;
  -webkit-transition: $param1,$param2;
  transition: $param1,$param2;
}
@mixin transStyle($parameters) {
	transform: $parameters;
	-moz-transform: $parameters;
    -ms-transform: $parameters;
    -webkit-transform: $parameters;
    -o-transform: $parameters;
}
@mixin torigin($parameters) {
	transform-origin: $parameters;
	-webkit-transfrom-origin: $parameters;
}
@mixin animDelay($parameters) {
	-o-transition-delay: $parameters;
	-ms-transition-delay: $parameters;
	-moz-transition-delay: $parameters;
	-webkit-transition-delay: $parameters;
	transition-delay: $parameters;
}
@mixin dropArrow() {
	color: inherit;
	border-style: solid;
	border-left-color: transparent;
	border-right-color: transparent;
}
@mixin overHidden() {
	position: relative;
	overflow: hidden;
}
@mixin psevdoAbs() {
	display: block;
	content: "";
	position: absolute;
}
@mixin coverLayer() {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
@mixin maskedInput() {
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: 0;
	visibility: hidden;
}
@mixin colCount($col) {
	-moz-column-count: $col;
    -webkit-column-count: $col;
    column-count: $col;
}
@mixin colGap($gap) {
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin customScroll($width, $height, $trackRadius, $barRadius, $trackBg, $barBg) {
	scrollbar-color: $barBg $trackBg;
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: $width;
		height: $height;
		border-radius: $trackRadius;
		background:  $trackBg;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $barRadius;
		background: $barBg;
	}
}


@mixin scrollbarY($width,$radius,$trackBg,$barBg,$barHoverBg) {
	.scroll-y {
        div {
            position: absolute;
            display: block;
            top: 0;
			bottom: 0;
			right: 0;
            border-radius: $radius;
            width: $width;
            &.scroll-element_track {
                z-index: 2;
                background: $trackBg;
            }
            &.scroll-bar {
                cursor: pointer;
                z-index: 3;
                background: $barBg;
                @extend %back300;
                &:hover {
                  background: $barHoverBg;
                }
            }
        }
    }
}

@mixin textOverflow($rowCount,$position) {
	position: $position;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $rowCount;
	-webkit-box-orient: vertical;
}

@mixin popupBody($background,$opacity, $transition) {
	&:before {
		display: block;
		position: fixed;
		content: "";
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		background: $background;
		opacity: 0;
		height: 0;
		@include animStyles(height 0.05s, opacity $transition);
		@include animDelay($transition);
   }
   &.popup_opened {
	   overflow: hidden;
	   &:before {
			height: 100%;
			opacity: $opacity;
			@include animDelay(0s);
	   }
   }
}

@mixin popupBlock($transition) {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	top: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1000;
	@include animStyle(top $transition);
	&.showed {
		top: 0;
	}
}

@mixin popupInner($padding) {
	display: flex;
	width: 100%;
	min-height: 100%;
	justify-content: center;
	align-items: center;
	padding: $padding;
}
@mixin popupContainer($background,$maxWidth, $padding) {
	background: $background;
	width: 100%;
	max-width: $maxWidth;
	padding: $padding;
	position: relative;
}

@mixin siteColorHover() {
	@extend %siteColorHover;
	@extend %siteColorActive;
}

@mixin secondColorHover() {
	@extend %secondColorHover;
	@extend %secondColorActive;
} 

@mixin siteBgHover() {
	@extend %siteBgHover;
	@extend %siteBgActive;
}

@mixin secondBgHover() {
	@extend %secondBgHover;
	@extend %secondBgActive;
}

@mixin secondDBgHover() {
	@extend %secondDBgHover;
	@extend %secondDBgActive;
}

@mixin opacityHover() {
	@extend %opacityHover;
	@extend %opacityActive;
}

@mixin linkHover() {
	@extend %linkHover;
	@extend %linkActive;
}

@mixin linkBgHover() {
	@extend %linkBgHover;
	@extend %linkBgActive;
}

@mixin blockHover() {
	@extend %blockHover;
	@extend %blockActive;
}

@mixin reactSelect() {
	.select_ {
        &_picker {
            outline: none !important;
        }
        &_single-value {
            margin: 0;
            color: $linkColor;
        }
        &_control {
            font-family: inherit;
            font-size: 160%;
            line-height: 21px;
            max-width: 100%;
            min-height: 0;
            background: $white;
            border-radius: 4px;
            border: 1px solid rgba($siteColor,0.15);
            cursor: pointer;
            outline: none;
            box-shadow: none;
            @include animStyle(border !important);
            &:hover,
            &:focus {
                border-color: $siteColor;
            }
            &--menu-is-open {
                border-color: $siteColor;
                &:hover,
                &:focus {
                    border-color: $siteColor;
                }
			}
			@media #{$until959} {
				border-color: transparent;
			}
        }
        &_value-container {
            padding: 0;
            height: auto;
            font-size: inherit;
            font-family: inherit;
            color: inherit;
            padding: 16px 13px;
            height: 53px;
            [class*="-Input"] {
                margin: 0;
                padding: 0;
                color: inherit;
                order: 0;
			}
			@media #{$until959} {
				padding-top: 11px;
				padding-bottom: 11px;
				height: 43px;
			}
        }
        &_placeholder {
            color: $textColor;
            margin: 0;
        }
        &_indicator-separator {
            display: none;
        }
        &_indicator {
			padding: 0 13px;
            color: $siteColor;
            svg {
                display: none;
			}
			&:before {
				@extend %iconElement;
				display: block;
				content: "\e901";
				font-size: 1.2rem;
				font-weight: 900;
				
			}
          &.select__clear-indicator {
            &:before {
              content: "\e904";
            }
            cursor: pointer;
            @include linkHover;
          }
        }
        &_menu {
            top: 100%;
            background: $white;
            box-shadow: none;
            margin: 0;
            z-index: 20;
            border-radius: 0;
            left: 0;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            border: 1px solid $siteColor;
            width: auto;
        }
        &_menu-list {
            padding: 10px 0;
            font-size: 140%;
            line-height: 20px;
        }
        &_option {
            padding: 8px 16px;
            color: inherit;
            outline: none;
            border: none;
            cursor: pointer;
            @extend %all300;
            background: transparent;
            word-break: break-word;
            &--is-selected {
				background: $textColor !important;
				color: $white;
                cursor: default;
            }
        }
        &_input {
            vertical-align: top;
        }
    }
}

@keyframes changeSkew {
    from {
        @include transStyle(skew(0));
    }
    to {
        @include transStyle(skew(-10deg));
    }
}
@keyframes rechangeSkew {
    from {
        @include transStyle(skew(0deg));
    }
    to {
        @include transStyle(skew(10deg));
    }
}

@keyframes changeMobSkew {
    from {
        @include transStyle(skew(0));
    }
    to {
        @include transStyle(skewY(-5deg));
    }
}
@keyframes rechangeMobSkew {
    from {
        @include transStyle(skew(0deg));
    }
    to {
        @include transStyle(skewY(5deg));
    }
}

@keyframes showElement {
    from {
        opacity: 0;
        @include transStyle(translate3d(0,20px,0));
    }
    to {
        opacity: 1;
        @include transStyle(translate3d(0,0,0));
    }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}
