%standardList {
	@include standardList();
}

%standardRowList {
	@include standardRowList();
}

%coverLayer {
	@include coverLayer();
}

%verticalRotate {
	@include transStyle(scaleX(-1));
}

%horizontalRotate {
	@include transStyle(scaleY(-1));
}

%centeredImage {
	> img {
		@extend %coverLayer;
		object-fit: cover;
	}
}

%coverVideo {
	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

%square {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 100%;
	}
}

%almostSquare {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 75%;
	}
}

%lessWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 62.5%;
	}
}

%standardWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 56.25%;
	}
}

%halfWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 50%;
	}
}

%superWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 25%;
	}
}

%standardNarrow {
	@include overHidden();
	@extend %centeredImage;
	&:before {
		display: block;
		content: "";
		padding-bottom: 177.78%;
	}
}

%lessNarrow {
	@include overHidden();
	@extend %centeredImage;
	&:before {
		display: block;
		content: "";
		padding-bottom: 133.33%;
	}
}

%iconElement {
	&:before,
	&:after {
		font-family: 'icon' !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

%noBlink {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

%smallBtn {
    border: 1px solid transparent;
    display: inline-block;
    vertical-align: top;
    border-radius: 12px;
    padding: 2px 14px;
    color: $white;
    background: $secondColor;
    font-size: 1.3rem;
	line-height: 18px;
	@media #{$until1330} {
		font-size: 1.2rem;
	}
	@media #{$until1199} {
		font-size: 1.1rem;
		line-height: 16px;
		padding: 2px 12px;
	}
	@extend %smallBtnHover;
	@extend %smallBtnActive;
}

%moreFillBtn {
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	background: $white;
	border: 1px solid transparent;
	border-radius: 30px;
	font-size: 140%;
	line-height: 19px;
	padding: 12px 22px;
	position: relative;
	&:after {
		@extend %iconElement;
		display: block;
		margin-left: 15px;
		margin-right: -5px;
		color: $secondColor;
		content: "\e909";
	}
	@extend %moreFillBtnHover;
	@extend %moreFillBtnActive;
}

%blueEmptyBtn {
	display: inline-block;
	vertical-align: top;
	color: $siteColor;
	padding: 10px 25px;
	border: 1px solid;
	border-radius: 25px;
	font-size: 160%;
	line-height: 23px;
	background: transparent;
	@extend %blueEmptyHover;
	@extend %blueEmptyActive;
}

%whiteEmptyBtn {
	display: inline-block;
	vertical-align: top;
	color: $white;
	padding: 10px 25px;
	border: 1px solid;
	border-radius: 25px;
	font-size: 160%;
	line-height: 23px;
	background: transparent;
	@extend %whiteEmptyHover;
	@extend %whiteEmptyActive;
}

%boxBtn {
	display: flex;
	align-items: center;
	box-shadow: 0 8px 22px $shadowColor;
	width: 100%;
	height: 100px;
	border-radius: 8px;
	@include overHidden();
	padding-right: 24px;
	font-size: 180%;
	line-height: 25px;
	color: $siteColor;
	@extend %boxBtnHover;
	@extend %boxBtnActive;
	z-index: 5;
	&:before {
		display: flex;
		width: 115px;
		min-width: 115px;
		margin-right: 13px;
		align-items: center;
		justify-content: center;
		font-size: 4.4rem;
		color: $white;
	}
	&:after {
		@include psevdoAbs();
		top: 0;
		width: 100%;
		right: 100%;
		height: 100%;
		content: "";
		z-index: -1;
		@include transStyle(skew(10deg));
		@include torigin(left center);
		background: $secondDarkColor;
		margin-right: -115px;
	}
	@media #{$until1199} {
		height: 80px;
		font-size: 160%;
		line-height: 22px;
		padding-right: 20px;
		&:before {
			width: 90px;
			min-width: 90px;
			font-size: 3.6rem;
		}
		&:after {
			margin-right: -90px;
		}
	}
	@media #{$until959} {
		height: 70px;
		font-size: 140%;
		line-height: 20px;
		padding-right: 15px;
		&:before {
			width: 70px;
			min-width: 70px;
			font-size: 3rem;
		}
		&:after {
			margin-right: -70px;
		}
	}
	@media #{$until767} {
		height: 100px;
		padding-right: 24px;
		font-size: 180%;
		line-height: 25px;
		&:before {
			width: 115px;
			min-width: 115px;
			font-size: 4.4rem;
		}
		&:after {
			margin-right: -115px;
		}
	}
	@media #{$until399} {
		height: 80px;
		font-size: 160%;
		line-height: 22px;
		&:before {
			width: 90px;
			min-width: 90px;
			font-size: 3.6rem;
		}
		&:after {
			margin-right: -90px;
		}
	}
}

%mainFillButton {
	border: none;
	padding: 0 25px;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 45px;
	font-size: 160%;
	line-height: 22px;
	color: $white;
	background: $siteColor;
	@include overHidden();
	border-radius: 4px;
	z-index: 3;
	@include linkBgHover();
	@extend %noBlink;
	&:before {
		@extend %coverLayer;
		content: "";
		background: $black;
		z-index: -1;
		opacity: 0;
	}
}

%secondFillButton {
	border: none;
	padding: 0 25px;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 45px;
	font-size: 160%;
	line-height: 22px;
	color: $white;
	background: $secondColor;
	@include overHidden();
	border-radius: 4px;
	z-index: 3;
	@include secondDBgHover();
	@extend %noBlink;
	&:before {
		@extend %coverLayer;
		content: "";
		background: $black;
		z-index: -1;
		opacity: 0;
	}
}