//font families
$regular: "notosans-regular", "notosansarm-regular";
$bold: "notosans-bold", "notosansarm-bold";

//media query sizes
$until1440: "(max-width: 1440px)";
$until1439: "(max-width: 1439px)";
$until1330: "(max-width: 1330px)";
$until1199: "(max-width: 1199px)";
$until991: "(max-width: 991px)";
$until959: "(max-width: 959px)";
$until768: "(max-width: 768px)";
$until767: "(max-width: 767px)";
$until639: "(max-width: 639px)";
$until599: "(max-width: 599px)";
$until575: "(max-width: 575px)";
$until524: "(max-width: 524px)";
$until479: "(max-width: 479px)";
$until399: "(max-width: 399px)";
$until359: "(max-width:359px)";
$until699h: "(max-height: 699px)";
$until719h: "(max-height: 719px)";
$until524h: "(max-height: 524px)";
$until399h: "(max-height: 399px)";
$until359h: "(max-height: 359px)";

//colors
$black: #000000;
$black33: #333333; 
$gray66: #666666;
$gray99: #999999;
$grayE5: #e5e5e5;
$white: #ffffff;
$creamyF2:#f2f2f2;
$error: #b94a48;
$siteColor: #281E78; //old #074494;
$secondColor: #f18e00;
$secondDarkColor: #ff5505; //#FE5000;
$linkColor:  #0063bd;
$linkHover: #00a3da;
$textColor: #5F6D7C;
$textColor2: #383B41;
$creamy: #F5F5F5;
$grayC9: #c9c9c9;
$shadowColor: rgba(#325E89,0.15);

//social icons original color
$facebookColor: #3b5998;
$twitterColor: #00acee;
$youtubeColor: #c4302b;
$behanceColor: #053eff;
$linkedinColor: #0e76a8;
$okColor: #ed812b;
$pinterestColor: #c8232c;
$vkColor: #4c75a3;
$tiktokColor: #EE1D52;
$telegramColor: #0088cc;
$instagramColor: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);

//sizes
$containerWidth: 1090px;
$containerPadding: 15px;
$rowMargin: -8px;
$colPadding: 8px;

//gradients
$mainGradient: linear-gradient(to right, $siteColor, $linkColor);
$mainGradientV: linear-gradient(to bottom, $siteColor, $linkColor);
$secondGradient: linear-gradient(to right, $secondColor, $secondDarkColor);

