@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.header {
    position: relative;
    z-index: 100;
    &_top {
        position: relative;
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 25px;
        background: $creamy;
        font-size: 130%;
        line-height: 18px;
        .search_form {
            margin-left: auto;
        }
    }
    &_bottom {
        position: relative;
        padding-left: 48px;
        display: flex;
        align-items: center;
        background: $white;
    }
    .logo_block {
        img {
            display: block;
            max-width: 151px;
            max-height: 37px;
        }
        a {
            display: block;
            @include opacityHover();
        }
    }
    .lg_block {
        position: relative;
        margin-left: 25px;
        z-index: 10;
        @include animStyle(z-index 0.5s);
        .lg_list {
            @extend %standardList;
            position: absolute;
            overflow: hidden;
            top: -5px;
            left: -10px;
            right: -10px;
            background: $white;
            border-radius: 8px;
            box-shadow: 0 8px 22px $shadowColor;
            max-height: 0;
            @include animStyle(max-height 0.5s);
            &:before {
                display: block;
                content: "";
                height: 30px;
            }
            &:after {
                display: block;
                content: "";
                height: 10px;
            }
            li {
                padding: 6px 12px;
                color: $linkColor;
                font-size: 100%;
                line-height: 18px;
                @include linkHover();
                cursor: pointer;
            }
        }
        .lg_btn {
            border: none;
            padding: 0;
            background: transparent;
            color: $linkColor;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            position: relative;
            z-index: 5;
            &:before {
                display: block;
                font-size: 0.6rem;
                margin-left: 7px;
                @include animStyle(transform 0.5s);
                @include torigin(center);
            }
            @include linkHover();
        }
        &.open {
            z-index: 200;
            .lg_list {
                max-height: 100px;
            }
            .lg_btn:before {
                @extend %horizontalRotate;
            }
        }
    }
    .sign_in_btn {
        @extend %smallBtn;
        margin-left: 30px;
    }
    .website_menu {
        @extend %standardList;
        display: flex;
        align-items: center;
        li:not(:first-child) {
            margin-left: 34px;
        }
        &:before {
            display: block;
            content: "";
            margin-right: 20px;
            height: 22px;
            border-left: 1px solid $grayC9;
        }
        a {
            display: block;
            color: $textColor;
            @include linkHover();
            &[href*="news"] {
                @include secondColorHover;
            }
        }
    }
    .search_block {
        position: relative;
        margin-left: auto;
        padding: 5px 15px;
        margin-right: 5px;
        color: transparent;
        z-index: 5;
        @include animStyle(color 0.3s);
        @include placeholder {
            color: inherit;
            opacity: 0.6;
        }
        input {
            position: absolute;
            background: $white;
            border-radius: 8px;
            right: 0;
            top: 0;
            border: none;
            box-shadow: 0 8px 22px $shadowColor;
            font-size: 100%;
            line-height: 18px;
            color: inherit;
            width: 0;
            padding: 5px 0;
            @include animStyles(all 0.5s, color 0s);
        }
        .submit_btn {
            border: none;
            color: $linkColor;
            padding: 0;
            background: transparent;
            font-size: 138.46%;
            position: relative;
            z-index: 5;
            @include linkHover();
            &:before {
                display: block;
            }
        }
        .clear_btn {
            position: absolute;
            right: 45px;
            top: 50%;
            margin-top: -9px;
            font-size: 1.2rem;
            color: $textColor;
            padding: 3px;
            border: none;
            background: transparent;
            z-index: 5;
            @include linkHover();
            &:before {
                display: block;
            }
        }
        &.open {
            color: $textColor;
            @include animDelay(0.2s);
            input {
                width: 340px;
                padding-left: 15px;
                padding-right: 75px;
            }
        }
    }
    .warning_info {
        flex: 1;
        width: 50%;
        padding: 0 25px;
        color: $linkColor;
        .short_part {
            white-space: nowrap;
        }
    }
    .menu_block {
        flex: 1;
        width: 50%;
    }
    .main_menu {
        @extend %standardList;
        display: flex;
        justify-content: flex-end;
        > li {
            > a {
                display: flex;
                padding: 22px 30px;
                height: 80px;
                color: $linkColor;
                width: min-content;
                font-size: 140%;
                line-height: 18px;
                max-width: 100%;
                letter-spacing: -0.5px;
                @extend %noBlink;
            }
            .touch_layer:before {
                @extend %iconElement;
            }
            @media screen and (min-width: 960px) {
                > .touch_layer {
                    display: block;
                    width: 100%;
                    height: 80px;
                    margin-top: -80px;
                    z-index: 5;
                    background: $white;
                    opacity: 0;
                    position: relative;
                    @include animStyle(height 0.01s);
                }
            }
            &:not(:last-child) a {
                @include linkHover();
                &[href*="news"] {
                    @include secondColorHover;
                }
            }
            &:last-child {
                > a {
                    color: $white !important;
                    background: $linkColor;
                    padding-left: 35px;
                    padding-right: 35px;
                    @include siteBgHover();
                }
                .submenu_list {
                    background: $linkColor;
                }
            }
            &.opened {
                &:not(:last-child) > a {
                    background: $textColor;
                    color: $white !important;
                }
                > .submenu_list {
                    height: auto;
                    opacity: 1;
                }
                @media screen and (min-width: 960px) {
                    > .touch_layer  {
                        height: 0;
                        @include animDelay(0.3s);
                    }
                }
            }
            > .submenu_list {
                position: absolute;
                overflow: hidden;
                top: 100%;
                left: 0;
                width: 100%;
                height: 0;
                background: rgba($textColor,0.95);
                color: $creamy;
                font-size: 150%;
                line-height: 20px;
                opacity: 0;
                z-index: 20;
                @include animStyle(opacity 0.3s);
                > ul {
                    padding: 15px 0;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    max-width: $containerWidth + 2*$containerPadding;
                    > li {
                        padding: 15px $containerPadding;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        > a {
                            font-family: $bold;
                            &:not(:last-child) {
                                @media #{$until959} {
                                    margin-bottom: 20px;
                                }
                                @media screen and (min-width: 960px) {
                                    margin-bottom: 30px;
                                }
                            }
                            
                            max-width: 280px;
                        }
                    }
                    ul {
                        @extend %standardList;
                        li {
                            max-width: 280px;
                        }
                        a {
                            margin-bottom: 20px;
                        }
                    }
                }
                a {
                    display: inline-block;
                    color: inherit;
                    vertical-align: top;
                }
            }
        }
    }
    
    .open {
        display: block!important;
        z-index: 3;
    }
    @media #{$until1330} {
        &_top {
            padding: 0 15px;
            font-size: 120%;
            line-height: 16px;
        }
        &_bottom {
            padding-left: 15px;
        }
        .lg_block,
        .sign_in_btn,
        .website_menu li:not(:first-child) {
            margin-left: 25px;
        }
        .warning_info {
            padding-left: 0;
        }
        .main_menu {
            > li {
                > a {
                    font-size: 130%;
                    line-height: 16px;
                    padding: 20px 25px;
                    
                }
                &:last-child > a {
                    padding-left: 30px;
                    padding-right: 30px;
                }
                > .submenu_list {
                    font-size: 140%;
                    line-height: 18px;
                }
            }
        }
        
    }
    @media #{$until1199} {
        &_top {
            font-size: 110%;
            line-height: 15px;
        }
        .lg_block,
        .sign_in_btn,
        .website_menu li:not(:first-child) {
            margin-left: 15px;
        }
        .warning_info {
            padding-right: 15px;
        }
        .website_menu:before {
            margin-right: 15px;
        }
        .main_menu {
            > li {
                > a {
                    font-size: 120%;
                    line-height: 15px;
                    padding: 15px;
                    height: 60px;
                }
                &:last-child > a {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                > .submenu_list {
                    font-size: 130%;
                    line-height: 16px;
                }
            }
        }
        
        .search_block {
            padding-right: 10px;
            padding-left: 10px;
            input {
                line-height: 15px;
            }
            .clear_btn {
                right: 35px;
            }
            &.open {
                input {
                    width: 320px;
                    padding-left: 10px;
                    padding-right: 55px;
                }
            }
        }
    }
    @media #{$until959} {
        &_top {
            font-size: 130%;
            line-height: 18px;
            background: transparent;
        }
        &_bottom {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .menu_btn {
            margin-right: $containerPadding;
            width: 22px;
            color: $siteColor;
            margin-left: auto;
            border: none;
            background: transparent;
            padding: 9px 0;
            position: relative;
            z-index: 100;
            @include animStyle(all 0.5s);
            &:before,
            &:after {
                @include psevdoAbs();
                border-top: 3px solid;
                width: 22px;
                border-radius: 2px;
                left: 0;
                @include animStyle(all 0.5s);
            }
            &:before {
                top: 0;
                @include torigin(left top);
            }
            &:after {
                bottom: 0;
                @include torigin(left bottom);
            }
            span {
                display: block;
                border-radius: 2px;
                border-top: 3px solid;
                @include animStyle(opacity 0.2s);
                @include animDelay(0.4s);
            }
        }
        .menu_block {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            overflow: hidden;
            @include animStyle(height 0.5s);
            background: $textColor;
            color: $white;
            z-index: 99;
        }
        .search_block {
            
            &:before {
                @include psevdoAbs();
                right: -5px;
                top: 50%;
                height: 22px;
                border-left: 1px solid $grayC9;
                margin-top: -11px;
            }
            input {
                line-height: 18px;
            }
            .clear_btn {
                right: 45px;
            }
            &.open {
                input {
                    width: 320px;
                    padding-left: 15px;
                    padding-right: 65px;
                }
            }
        }
        .sign_in_btn {
            font-size: 100%;
            line-height: 18px;
            padding: 2px 14px;
        }
        .lg_block {
            margin-left: 25px;
        }
        .sign_lg {
            display: flex;
            align-items: center;
            font-size: 130%;
            line-height: 17px;
            .lg_block {
                margin-left: 0;
            }
            .lg_btn {
                color: $white;
            }
            .sign_in_btn {
                margin-left: 40px;
            }
        }
        
        .menu_inner {
            position: relative;
            padding: 40px 24px;
            height: 100%;
            min-height: 90vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .main_menu {
            display: block;
            flex: none;
            width: 100%;
            > li {
                margin-top: 20px;
                position: relative;
                > a {
                    padding: 0;
                    color: $white;
                    width: auto;
                    height: auto;
                    font-size: 140%;
                    line-height: 18px;
                    display: inline-flex;
                    vertical-align: top;
                    
                    
                }
                &:not(:last-child) {
                    a {
                        max-width: 250px;
                    }
                    > a.submenu_btn {
                        margin-right: 30px;
                    }
                } 
                
                > .touch_layer {
                    position: absolute;
                    right: -5px;
                    top: 0;
                    padding: 5px;
                    &:before {
                        display: block;
                        position: relative;
                        content: "\e901";
                        font-size: 1.2rem;
                        font-weight: 900;
                    }
                }
                &:last-child {
                    > .touch_layer {
                        top: 28px;
                    }
                    .submenu_list {
                        margin: 0 #{-$containerPadding - 10};
                        width: auto;
                        padding: 0 $containerPadding + 10;
                        &:after {
                            display: block;
                            content: "";
                            height: 30px;
                        }
                        > ul > li:first-child {
                            padding-top: 5px;
                        }
                    }
                }
                &.opened > .touch_layer:before {
                    @include transStyle(scaleY(-1));
                }
                &:last-child > a {
                    display: block;
                    padding: 30px 0;
                    position: relative;
                    &:before,
                    &:after {
                        @include psevdoAbs();
                        top: 0;
                        width: 24px;
                        height: 100%;
                        background: inherit;
                    }
                    &:before {
                        left: 100%;
                    }
                    &:after {
                        right: 100%;
                    }
                }
                > .submenu_list {
                    position: relative;
                    top: auto;
                    left: auto;
                    background: transparent;
                    font-size: 150%;
                    line-height: 20px;
                    > ul {
                        display: block;
                        padding: 0;
                        > li {
                            padding: 25px 10px 0;
                            max-width: 280px;
                            position: relative;
                        }
                        ul {
                            opacity: 0.9;
                        }
                    }
                }
            }
        }
        .website_menu {
            display: block;
            &:before {
                display: none;
            }
            a {
                color: $white;
                font-size: 130%;
                line-height: 18px;
            }
            li {
                margin-top: 15px;
                &:not(:first-child) {
                    margin-left: 0;
                }
            }
        }
        
        
    }
    @media #{$until768} {
        .warning_info {
            position: absolute;
            left: 5px;
            top: 4px;
            border-radius: 8px;
            padding: 5px 10px;
            flex: none;
            width: auto;
            max-width: 95vw;
            font-size: 0;
            @include animStyles(all 0.3s, font-size 0s);
            z-index: 99;
            .short_part {
                font-size: 1.3rem;
            }
            &:hover {
                background: $white;
                box-shadow: 0 8px 22px $shadowColor;
                font-size: 100%;
                @include animDelay(0s);
            }
        }
    }
    @media #{$until575} {
        .search_block.open input {
            width: 300px;
        }
    }
    @media #{$until479} {
        .search_inner {
            position: absolute;
            z-index: 100;
            left: 0;
            right: 0;
            top: -36px;
            background: $creamy;
            padding: 4px $containerPadding;
            @include animStyle(top 0.5s);
            input {
                background: $white;
                border-radius: 8px;
                border: none;
                box-shadow: 0 8px 22px $shadowColor;
                line-height: 18px;
                color: inherit;
                width: 100%;
                padding: 5px 65px 5px 15px;
            }
            .submit_btn {
                position: absolute;
                top: 50%;
                margin-top: -9px;
                right: 15 + $containerPadding;
                border: none;
                color: $linkColor;
                padding: 0;
                background: transparent;
                font-size: 138.46%;
                z-index: 5;
                &:before {
                    display: block;
                }
                @include animStyle(color 0.15s);
                &:active {
                    color: $linkColor;
                }
            }
            .clear_btn {
                position: absolute;
                right: 45 + $containerPadding;
                top: 50%;
                margin-top: -9px;
                font-size: 1.2rem;
                color: $textColor;
                padding: 3px;
                border: none;
                background: transparent;
                z-index: 5;
                &:before {
                    display: block;
                }
                @include animStyle(color 0.15s);
                &:active {
                    color: $linkColor;
                }
            }
            &.open {
                top: 0;
            }
        }
    }
    @media #{$until399} {
        .lg_block {
            margin-left: 15px;
        }
        .search_block {
            padding-right: 16px;
        }
    }
}

@media #{$until959} {
    .menu_opened {
        overflow: hidden;
        .menu_btn {
            color: $white;
            @include transStyle(translate3d(10px,-48px,0));
            @include animStyles(transform 0.5s, color 0.1s);
            span {
                opacity: 0;
                @include animDelay(0s);
            }
            &:before {
                @include transStyle(rotate(45deg));
                left: 2px;
                top: 1px;
                width: 24px;
            }
            &:after {
                @include transStyle(rotate(-45deg));
                bottom: 1px;
                left: 2px;
                width: 24px;
            }
        }
        .menu_block {
            height: 100%;
        }
    }
}

.web .header .touch_layer {
    @media screen and (min-width: 960px) {
        display: none !important;
    }
}




@import '../../default-scss/extendsEnd';