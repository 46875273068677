html {
    height: 100%;
    font-size: 10px;
    line-height: 1.4286em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    color: $textColor;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    min-height: 100%;
    font-family: $regular;
    background: $creamy;
    @include popupBody($black,0.7,0s);
}

*,
*:before,
*:after {
    box-sizing: border-box;
    
}

input, 
select, 
textarea, 
button, 
a, 
.slick-slider div {
   outline: none !important;
}

a,
a:focus,
a:hover,
button:hover {
    text-decoration: none;
}

a {
    color: $linkColor;
}

button {
    cursor: pointer;
    display: block;
    font-weight: normal;
    font-family: inherit;
    font-size: 100%;
}

img	{
    max-height: none;
    border: none;
}

textarea {
    font-family: inherit;
}

.custom_row {
    margin-left: $rowMargin;
    margin-right: $rowMargin;
    display: flex;
    flex-wrap: wrap;
    .cb {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.grecaptcha-badge { opacity:0;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

[class*="col-"] {
    padding-left: $colPadding;
    padding-right: $colPadding;
}

.custom_container {
    width: 100%;
    max-width: $containerWidth + 2*$containerPadding;
    padding-left: $containerPadding;
    padding-right: $containerPadding;
    margin: 0 auto;
}

.phone_link:not(.clickable) {
    color: inherit;
    cursor: default;
    pointer-events: none;
}

.individual_hint,
.drop_list {
    display: none;
}
.error_hint {
    display: block;
    max-height: 0;
    @extend %all300;
    overflow: hidden;
    color: $error;
}

.has-error {
    @include placeholder {
        color: rgba($error, 0.7) !important;
    }
}
.error_message {
    font-size: 120%;
    color: $error;
    line-height: 15px;
    margin-top: 2px;
    padding: 0 15px;
}
.field_block {
    position: relative;
    width: 100%;
    padding: 0 10px 0 0;
    textarea,
    input {
        width: 100%;
        border: 1px solid rgba($siteColor, 0.15);
        border-radius: 4px;
        font-size: 160%;
        line-height: 21px;
        padding: 16px 13px;
        background: $white;
        color: $linkColor;
        @extend %all300;
        &:focus {
            border-color: $siteColor;
        }
        @media #{$until959} {
            padding-top: 11px;
            padding-bottom: 11px;
            border-color: transparent;
        }
    }
    textarea {
        resize: none;
        display: block;
        height: 120px;
   }
   
   .field_name {
        display: inline-block;
        vertical-align: top;
        font-size: 140%;
        line-height: 19px;
        margin-bottom: 10px;
    }
    .radio_group {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px -20px;
        .radio_btn {
            @extend %textUnSelect;
            cursor: pointer;
            border-radius: inherit;
            font-size: 170%;
            line-height: 22px;
            display: flex;
            height: 45px;
            padding: 0 20px;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $linkColor;
            border: 1px solid rgba($siteColor, 0.5);
            @extend %all300;
            &:hover {
                color: $white;
                border-color: transparent;
                background: $siteColor;
            }
            @media #{$until959} and (min-width: 576px) {
                font-size: 160%;
                padding: 0 15px;
            }
            @media #{$until399} {
                font-size: 160%;
                padding: 0 15px;
            }
        }
        label {
            @include overHidden();
            margin: 0 10px 20px;
            border-radius: 4px;
            input {
                @extend %maskedInput;
                &:checked + .radio_btn {
                    color: $white;
                    border-color: transparent;
                    background: $linkColor;
                    cursor: default;
                }
            }
        }
    }
    
}
.cb {
    display: block;
    clear: both;
}

.section_title {
    text-align: center;
    margin: 0;
    color: $linkColor;
    font-size: 300%;
    line-height: 36px;
    font-family: $bold;
    @media #{$until1199} {
        font-size: 280%;
        line-height: 34px;
    }
    @media #{$until959} {
        font-size: 260%;
        line-height: 32px;
    }
    @media #{$until767} {
        font-size: 240%;
        line-height: 32px;
    }
    @media #{$until575} {
        font-size: 200%;
        line-height: 32px;
    }
    @media #{$until399} {
        font-size: 180%;
        line-height: 32px;
    }
    + .content_inner,
    + .page_short_info {
        margin-top: 20px;
    }
}

.page_title {
    margin: 0;
    color: $linkColor;
    font-size: 360%;
    line-height: 1.22em;
    font-family: $bold;
    @media #{$until1199} {
        font-size: 320%;
    }
    @media #{$until959} {
        font-size: 300%;
    }
    @media #{$until767} {
        font-size: 270%;
    }
    @media #{$until575} {
        font-size: 240%;
    }
    @media #{$until399} {
        font-size: 200%;
    }
}

.section_subtitle {
    color: $linkColor;
    font-size: 240%;
    line-height: 30px;
    margin: 0 0 20px;
    font-family: $bold;
    a {
        display: inline-block;
        vertical-align: top;
        color: inherit;
        @include linkHover();
    }  
    @media #{$until1199} {
        font-size: 220%;
    }
    @media #{$until959} {
        font-size: 200%;
    }
    @media #{$until767} {
        font-size: 180%;
    }
    @media #{$until575} {
        font-size: 160%;
    }
}
.page_subtitle {
    color: $linkColor;
    font-size: 170%;
    line-height: 23px;
    margin: 0;
    font-family: $regular;
    font-weight: normal;
    a {
        display: inline-block;
        vertical-align: top;
        color: inherit;
        @include linkHover();
    }   
    @media #{$until399} {
        font-size: 150%;
        line-height: 20px;
    }
}

.content_inner,
.standard_text {
    font-size: 160%;
    line-height: 22px;
    color: $textColor2;
    .content_subtitle,
    .bold,
    h2, h3, h4 {
        font-size: inherit;
        margin: 0;
        font-family: $bold;
        color: $black;
    }
    a {
        color: $linkColor;
        @include linkHover();
    }
    img {
        max-width: 100%;
        height: auto;
    }
    @media #{$until399} {
        font-size: 140%;
    }
    &:empty {
        display: none;
    }
}

.standard_text {
    padding-top: 40px;
}

.section_head {
    padding-bottom: 30px;
    .section_description {
        padding-top: 30px;
        max-width: 910px;
        font-size: 160%;
        line-height: 27px;
        margin: 0 auto;
        text-align: center;
    }
    @media #{$until575} {
        padding-bottom: 20px;
    }
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page_short_info {
    font-size: 160%;
    line-height: 22px;
    color: $textColor2;
    max-width: 950px;
    text-align: center;
    margin: 0 auto;
    p {
        margin: 0;
    }
    &:not(:first-child) {
        margin-top: 20px;
    }
    @media #{$until399} {
        font-size: 140%;
        line-height: 19px;
    }
}

.standard_page {
    padding: 50px 0;
    @media #{$until959} {
        padding: 45px 0;
    }
    @media #{$until767} {
        padding: 40px 0;
    }
    .page_title,
    .page_subtitle {
        text-align: center;
    }
    .page_subtitle {
        margin-bottom: 5px;
    }
}

.page_content {
    padding-bottom: 100px;
    @media #{$until959} {
        padding-bottom: 80px;
    }
    @media #{$until767} {
        padding-bottom: 60px;
    }
}
.right_content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 $colPadding;
}
.sidebar_menu {
    flex: 0 0 25%;
    max-width: 200 + 2*$colPadding;
    padding: 0 $colPadding;
    margin-right: auto;
    .bnner_block {
        margin-top: 40px;
        .bnner_inner {
            display: block;
            @include overHidden();
            background: $linkHover;
            color: $white;
        }
        a.bnner_inner {
            @extend %noBlink;
            @include opacityHover();
        }
        .image_block {
            display: block;
            height: 600px;
            @extend %noBlink;
            @include overHidden();
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @extend %noBlink;
        }
        .info_block {
            display: none;
            align-items: center;
            justify-content: center;
            font-size: 120%;
            line-height: 15px;
            padding: 20px;
            height: 140px;
        }
    }
    ul {
        @extend %standardList;
    }
    > ul {
        
        > li {
            padding-bottom: 22px;
            > a {
                font-size: 170%;
                line-height: 22px;
            }
            > .submenu_list {
                padding-left: 20px;
                > ul > li {
                    padding-top: 19px;
                    > a {
                        font-size: 140%;
                        line-height: 19px;
                    }
                    > .submenu_list {
                        padding-left: 15px;
                        > ul > li {
                            padding-top: 15px;
                            > a {
                                font-size: 120%;
                                line-height: 17px;
                            }
                        }
                    }
                }
            }
        }
        a {
            display: inline-block;
            vertical-align: top;
            &:not(.active) {
                color: $linkColor;
                @include linkHover();
            }
            &.active {
                color: $secondColor;
                font-family: $bold;
                display: inline-flex;
                vertical-align: top;
                @include secondColorHover();
                &:before {
                    @extend %iconElement;
                    font-size: 1.2rem;
                    content: "\e909";
                    margin-right: 10px;
                    line-height: inherit;
                    font-weight: 900;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    + .right_content {
        @media screen and (min-width: 768px) {
            flex: 0 0 75%;
            max-width: 75%;
            .section_title,
            .page_short_info {
                text-align: left;
                margin-left: 0;
            }
            .services_list {
                justify-content: flex-start;
            }
        }
    }
    @media #{$until959} {
        .bnner_block {
            margin-top: 30px;
        }
        > ul {
            > li {
                padding-bottom: 20px;
                > a {
                    font-size: 150%;
                    line-height: 20px;
                }
                > .submenu_list {
                    padding-left: 15px;
                    > ul > li {
                        padding-top: 17px;
                        > a {
                            font-size: 130%;
                            line-height: 17px;
                        }
                        > .submenu_list {
                            padding-left: 10px;
                            > ul > li {
                                padding-top: 12px;
                                > a {
                                    font-size: 120%;
                                    line-height: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media #{$until767} {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        margin-top: 30px;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 30px;
        .bnner_block {
            margin-top: 0;
            margin-bottom: 30px;
            .bnner_inner {
                display: flex;
            }
            .image_block {
                height: 160px;
                flex: 0 0 100%;
                max-width: 100%;

                img {
                    width: 120%;
                }
            }
            .info_block {
                flex: 0 0 45%;
                max-width: 45%;
                height: 160px;
            }
        }
        > ul {
            > li {
                padding-bottom: 22px;
                &:last-child {
                    padding-bottom: 0;
                }
                > a {
                    font-size: 170%;
                    line-height: 22px;
                }
                > .submenu_list {
                    padding-left: 20px;
                    > ul > li {
                        padding-top: 19px;
                        > a {
                            font-size: 140%;
                            line-height: 19px;
                        }
                        > .submenu_list {
                            padding-left: 15px;
                            > ul > li {
                                padding-top: 15px;
                                > a {
                                    font-size: 120%;
                                    line-height: 17px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.service_lg_btn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 460px;
    border-radius: 8px;
    box-shadow: 0 8px 22px $shadowColor;
    padding-top: 25px;
    background: $white;
    @include overHidden();
    &:before {
        display: block;
        @extend %iconElement;
        color: $secondColor;
        font-size: 140%;
        margin-left: 30px;
        content: "\e909";
        order: 1;
    }
    p {
        margin: 0;
    }
    .service_title {
        display: block;
        font-size: 180%;
        line-height: 24px;
        font-family: $bold;
        color: $siteColor;
        padding: 0 30px 10px;
    }
    .service_description,
    .short_description {
        font-size: 140%;
        line-height: 20px;
        display: block;
        padding: 0 30px;
        margin-bottom: 10px;
        @include textOverflow(4,relative);
        color: $textColor;
        z-index: 2;
    }
    .image_block {
        width: 100%;
        order: 2;
        margin-top: auto;
        border-top-left-radius: 60px;
        box-shadow: 12px 22px 23px $shadowColor;
        margin-left: 30px;
        margin-bottom: -30px;
        @include overHidden();
        @include torigin(left bottom);
        @include transStyle(rotate(10deg));
        &:before {
            display: block;
            content: "";
            padding-bottom: 75%;
        }
        img {
            @extend %coverLayer;
            object-fit: cover;
            @extend %noBlink;
        }
    }
    @media #{$until1199} and (min-width: 960px) {
        .image_block,
        &:before {
            margin-left: 20px;
        }
        .service_title {
            padding: 0 20px 10px;
            font-size: 160%;
            line-height: 22px;
        }
        .service_description,
        .short_description {
            padding: 0 20px;
        }
    }
    @media #{$until767} and (min-width: 576px) {
        .image_block,
        &:before {
            margin-left: 20px;
        }
        .service_title {
            padding: 0 20px 10px;
            font-size: 160%;
            line-height: 22px;
        }
        .service_description,
        .short_description {
            padding: 0 20px;
            font-size: 130%;
        }
    }
    @media #{$until399} {
        .image_block,
        &:before {
            margin-left: 20px;
        }
        .service_title {
            padding: 0 20px 10px;
            font-size: 160%;
            line-height: 22px;
        }
        .service_description,
        .short_description {
            padding: 0 20px;
        }
    }
}

a.service_lg_btn,
a.service_wide {
    @include blockHover();
}

.styled_table {
    border-radius: 8px;
    box-shadow: 0 8px 22px $shadowColor;
    margin-top: 40px;
    border-spacing: 0;
    background: $white;
    font-size: 1rem;
    word-break: normal;
    max-width: 100%;
    p {
        margin: 0;
    }
    thead {
        background: $linkColor;
        
        td,
        th {
            font-family: $regular;
            text-align: left;
            height: 85px;
            font-size: 110%;
            line-height: 15px;
            color: $white;
            
        }
        tr:first-child:not(:last-child) {
            th,
            td {
                &[colspan*=""] {
                    text-align: center;
                }
                &:not([rowspan*=""]) {
                    border-bottom: 1px solid $white;
                }
            }
        }
    }
    th,
    td {
        padding: 15px;
        &:not(:first-child) {
            border-left: 1px solid $white;
        }
        &:first-child {
            min-width: 95px;
        }
    }
    
    tbody td {
        height: 70px;
        color: $linkColor;
        font-size: 140%;
        line-height: 19px;
    }
    tbody tr:nth-child(2n) {
        background: rgba($grayC9,0.15);
    }
    img {
        max-width: 36px;
        max-height: 36px;
    }
}
.table_block {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid $white;
    box-shadow: 0 8px 22px $shadowColor;
    background: $white;
    table {
        min-width: 100%;
        border-spacing: 0;
        font-size: 1rem;
        word-break: normal;
        margin-left: -1px;
        p {
            margin: 0;
        }
    }
    thead td,
    thead th {
        text-align: left;
        font-family: $regular;
        background: $linkColor;
        color: $white;
        font-size: 130%;
        line-height: 17px;
        padding: 10px 15px;
        height: 84px;
        position: relative;
        border-left: 1px solid $white;
    }
    thead {
        tr:first-child:not(:last-child) {         
            th,
            td {
                &[colspan] {
                    text-align: center;
                }
                &:not([rowspan]) {
                    border-bottom: 1px solid $white;
                }
            }
        }
    }
    td {
        padding: 10px 15px;
        vertical-align: top;
        font-size: 140%;
        line-height: 19px;
        color: $linkColor;
        &:not(:first-child) {
            border-left: 1px solid $white;
        }
    }
    .highlight_row,
    tbody th {
        background: $creamy;
        font-family: $bold;
    }
    tr:not(:first-child) td {
        border-top: 1px solid $creamy;
    }
}
.table_container {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}
.standard_table {
    max-width: 100%;
    border-spacing: 0;
    border-top: 1px solid $white;
    font-size: 1rem;
    box-shadow: 0 8px 22px $shadowColor;
    background: $white;
    word-break: normal;
    p {
        margin: 0;
    }
    thead td,
    thead th {
        text-align: left;
        font-family: $regular;
        background: $linkColor;
        color: $white;
        font-size: 130%;
        line-height: 17px;
        padding: 10px 15px;
        height: 84px;
        &:not(:first-child) {
            border-left: 1px solid $white;
        }
    }
    td {
        padding: 10px 15px;
        vertical-align: top;
        font-size: 140%;
        line-height: 19px;
        color: $linkColor;
        &:not(:first-child) {
            border-left: 1px solid $white;
        }
    }
    .highlight_row,
    tbody th {
        background: $creamy;
        font-family: $bold;
    }
    tr:not(:first-child) td {
        border-top: 1px solid $creamy;
    }
}

figure {
    margin: 20px 0;
    display: block;
    width: 100%;
}

.bold_text {
    font-family: $bold;
}

.hour_block {
    white-space: nowrap;
}

.horizontal_bnner {
    .bnner_block {
        @include overHidden();
        margin-top: 30px;
        .bnner_inner {
            @include overHidden();
            background: $linkHover;
            color: $white;
        }
        a .bnner_inner {
            display: block;
            @extend %noBlink;
            @include opacityHover();
        }
        .image_block {
            height: 160px;
            @extend %noBlink;
            @include overHidden();
            img {
                width: 100%;
            }
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @extend %noBlink;
        }
        .info_block {
            display: none;
        }
    }
}