%opacity300 {
    @include animStyle(opacity 0.3s);
}

%color300 {
    @include animStyle(color 0.3s);
}

%back300 {
    @include animStyle(background-color 0.3s);
}

%all300 {
    @include animStyle(all 0.3s);
}

%opacity150 {
    @include animStyle(opacity 0.15s);
}

%color150 {
    @include animStyle(color 0.15s);
}

%back150 {
    @include animStyle(background-color 0.15s);
}

%all150 {
    @include animStyle(all 0.15s);
}

%opacityHover07 {
    &:hover {
        opacity: 0.7;
    }
}

%opacityHover1 {
    &:hover {
        opacity: 1;
    }
}

%blackColorHover {
    &:hover {
        color: $black;
    }
}

%whiteHover07 {
    &:hover {
        color: rgba($white,0.7);
    }
}

%whiteColorHover {
    &:hover {
        color: $white;
    }
}

%textUnSelect {
	-webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}

%maskedInput {
    @include maskedInput();
}

.web {
    %socColorHover {
        a {
            @extend %color300;
        }
        .icon_youtube:hover {
            color: $youtubeColor;    
        }
        .icon_behance:hover {
            color: $behanceColor;    
        }
        .icon_facebook:hover {
            color: $facebookColor;    
        }
        .icon_instagram:hover {
            background: $instagramColor;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .icon_linkedin:hover {
            color: $linkedinColor;    
        }
        .icon_ok:hover {
            color: $okColor;    
        }
        .icon_pinterest:hover {
            color: $pinterestColor;    
        }
        .icon_twitter:hover {
            color: $twitterColor;    
        }
        .icon_vk:hover {
            color: $vkColor;    
        }
        .icon_tiktok:hover {
            color: $tiktokColor;    
        }
        .icon_telegram:hover {
            color: $telegramColor;    
        }
    }
}

.touch {
    %socColorHover {
        a {
            @extend %color150;
        }
        .icon_youtube:active {
            color: $youtubeColor;    
        }
        .icon_behance:active {
            color: $behanceColor;    
        }
        .icon_facebook:active {
            color: $facebookColor;    
        }
        .icon_instagram:active {
            background: $instagramColor;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .icon_linkedin:active {
            color: $linkedinColor;    
        }
        .icon_ok:active {
            color: $okColor;    
        }
        .icon_pinterest:active {
            color: $pinterestColor;    
        }
        .icon_twitter:active {
            color: $twitterColor;    
        }
        .icon_vk:active {
            color: $vkColor;    
        }
        .icon_tiktok:active {
            color: $tiktokColor;    
        }
        .icon_telegram:active {
            color: $telegramColor;    
        }
    }
}

.web {
    %moreFillBtnHover {
        @extend %all300;
        &:after {
            @extend %color300;
        }
        &:hover {
            border-color: $white;
            color: $white;
            background: transparent;
            &:after {
                color: $white;
            }
        }
    }

    %siteColorHover {
        @extend %color300;
        &:hover {
            color: $siteColor;
        }
    }
    %secondColorHover {
        @extend %color300;
        &:hover {
            color: $secondDarkColor;
        }
    }
    %siteBgHover {
        @extend %back300;
        &:hover {
            background: $siteColor;
        }
    }
    %secondBgHover {
        @extend %back300;
        &:hover {
            background: $secondColor;
        }
    }
    %secondDBgHover {
        @extend %back300;
        &:hover {
            background: $secondDarkColor;
        }
    }
    %opacityHover {
        @extend %opacity300;
        &:hover {
            opacity: 0.8;
        }
    }
    %linkHover {
        @extend %color300;
        &:hover {
            color: $linkHover;
        }
    }
    %linkBgHover {
        @extend %back300;
        &:hover {
            background: $linkHover;
        }
    }
    %smallBtnHover {
        @extend %all300;
        &:hover {
            border-color: $secondColor;
            color: $secondColor;
            background: transparent;
        }
    }
    %blueEmptyHover {
        @extend %all300;
        &:hover {
            background: $siteColor;
            border-color: transparent;
            color: $white;
        }
    }
    %whiteEmptyHover {
        @extend %all300;
        &:hover {
            background: $white;
            border-color: transparent;
            color: $linkColor;
        }
    }
	%blockHover {
        @extend %back300;
        [class*="title"],
        [class*="description"] {
            @extend %color300;
        }
        .image_block {
            @extend %all300;
        }
        &:hover {
            background: $siteColor;
            color: $white;
            * {
                color: inherit;
            }
            .image_block {
                @include transStyle(rotate(0deg))
            }
        }
    }

    %boxBtnHover {
        @extend %color300;
        &:before {
            @extend %color300;
        }
        &:after {
            @extend %all300;
        }
        &:hover {
            color: $white;
            &:before {
                color: $secondDarkColor;
            }
            &:after {
                right: 0;
            }
        }
    }

    %mainFillHover {
        &:before {
            @extend %opacity300;
        }
        &:hover:before {
            opacity: 0.2;
        }
    }
}

.touch {
    %moreFillBtnActive {
        @extend %all150;
        &:after {
            @extend %color150;
        }
        &:active {
            border-color: $white;
            color: $white;
            background: transparent;
            &:after {
                color: $white;
            }
        }
    }
    %siteColorActive {
        @extend %color150;
        &:active {
            color: $siteColor;
        }
    }
    %secondColorActive {
        @extend %color150;
        &:active {
            color: $secondDarkColor;
        }
    }
    %siteBgActive {
        @extend %back150;
        &:active {
            background: $siteColor;
        }
    }
    %secondBgActive {
        @extend %back150;
        &:active {
            background: $secondColor;
        }
    }
    %secondDBgActive {
        @extend %back150;
        &:active {
            background: $secondDarkColor;
        }
    }
    %opacityActive {
        @extend %opacity150;
        &:active {
            opacity: 0.8;
        }
    }
    %linkActive {
        @extend %color150;
        &:active {
            color: $linkHover;
        }
    }
    %linkBgActive {
        @extend %back150;
        &:active {
            background: $linkHover;
        }
    }
    %smallBtnActive {
        @extend %all150;
        &:active {
            border-color: $secondColor;
            color: $secondColor;
            background: transparent;
        }
    }
    %blueEmptyActive {
        @extend %all150;
        &:active {
            background: $siteColor;
            border-color: transparent;
            color: $white;
        }
    }
    %whiteEmptyActive {
        @extend %all150;
        &:active {
            background: $white;
            border-color: transparent;
            color: $linkColor;
        }
    }
    %blockActive {
        @extend %back150;
        [class*="title"],
        [class*="description"] {
            @extend %color150;
        }
        .image_block {
            @extend %all150;
        }
        &:active {
            background: $siteColor;
            color: $white;
            * {
                color: inherit;
            }
            .image_block {
                @include transStyle(rotate(0deg))
            }
        }
    }
    
    %boxBtnActive {
        @extend %color150;
        &:before {
            @extend %color150;
        }
        &:after {
            @extend %all150;
        }
        &:active {
            color: $white;
            &:before {
                color: $secondDarkColor;
            }
            &:after {
                right: 0;
            }
        }
    }
    %mainFillActive {
        &:before {
            @extend %opacity150;
        }
        &:active:before {
            opacity: 0.2;
        }
    }
}

